import axios from '@/plugins/axios.js'

// 查询
export const macSearch = (params) => axios.post('/tb-mac/selectMac', params)

// 批量删除
export const removeMacBatch = (data) => axios.delete('/tb-mac/deleteGroup', { data })

// 下载
export const downloadMac = (id) =>
  axios.get(`/tb-mac/downloadFile/com.aledevice:ale-edm:jar:1.1.0-SNAPSHOT`, {
    responseType: 'blob',
  })

// 导出
export const macExport = (params) => axios.post('/tb-mac/export', params, { responseType: 'blob' })
export const macExportAll = (params) => axios.post('/tb-mac/exportAll', params, { responseType: 'blob' })

// 导入失败数据导出
export const macExportFailedData = (params) => {
  return axios.post('/tb-mac/exportFailedData', params, { responseType: 'blob' })
}
